import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Avatar from "@mui/material/Avatar";
import "../assets/common.css";
import Container from "./Container";
import { Nevin } from "../assets/images";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Modal,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Backdrop,
  CardMedia,
  CardContent,
  Divider,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { api, helpers, UserContext, constants } from "../utils";
import ProfileLayout from "../layouts/ProfileLayout";
import vCard from "vcf";
import ArticlesOnProfile from "./ArticlesOnProfile";
import UserProfile from "./UserProfile2";
import Cookies from "js-cookie";
import _ from "lodash";

export default function Profile() {
  const defaultNewUser = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    mobilePhoneNumber: "",
    timezone: constants.DEFAULT_TIMEZONE,
    roles: [],
  };
  const [profileData, setProfileData] = useState(null);
  const userCtx = useContext(UserContext);
  const { userId, profileNumber, id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userCard, setUserCard] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [leadPhoneNumber, setLeadPhoneNumber] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCompany, setLeadCompany] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [customApps, setCustomApps] = useState([]);
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const location = useLocation();
  const [bottomColor, setBottomColor] = useState("#000");
  const [primaryColor, setPrimaryColor] = useState("#000");
  const [secondaryColor, setSecondaryColor] = useState("#000");
  const [highlightColor, setHighlightColor] = useState("#000");
  const [activationCode, setActivationCode] = useState("");
  const [activationError, setActivationError] = useState("");
  const [profile, setProfile] = useState();
  const [userProfileId, setUserProfileId] = useState();
  const [userCompanyProfileId, setUserCompanyProfileId] = useState();
  const { profileTheme } = useState(location.state ? location.state : null);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const theme = useTheme();
  const [companyPanelColor, setCompanyPanelColor] = useState("#111");
  const [useWhiteText, setUseWhiteText] = useState(true);
  const [textColor, setTextColor] = useState("fff");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [namePanelColor, setNamePanelColor] = useState("#111");
  const [updateProfile, setUpdateProfile] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [profileEmail, setProfileEmail] = useState('');
  const [profilePhone, setProfilePhone] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');


  const trackVisit = async (userProfileId) => {
    const sessionId = Cookies.get("sessionID");
      await api.post("public/trackProfileVisit", {
      sessionId: sessionId,
      userProfileId: userProfileId,
    });
    console.log(userProfileId, "USERPROFILEIDHERE");
  };
  
  const trackCompanyVisit = async (userCompanyProfileId) => {
    const sessionId = Cookies.get("sessionID");
    
      await api.post("public/TrackUserCompanyProfileVisit", {
      sessionId: sessionId,
      userCompanyProfileId: userCompanyProfileId,
   } )
    }

  const trackLinkClick = (userProfileAppId, event) => {
    event.preventDefault();
    const sessionId = Cookies.get("sessionID");
    api
      .post(`public/trackLinkClick/${userProfileAppId}`, {
        sessionID: sessionId,
        userProfileAppId: userProfileAppId,
      })
      .then(() => {
        console.log("Link click tracked for app:", userProfileAppId);
      })
      .catch((error) => {
        console.error("Failed to track link click:", error);
      });
  };

  const AppPlaceholder = () => (
    <Box
      sx={{
        height: "3rem",
        width: "3rem",
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
        backgroundColor: "transparent",
      }}
    />
  );

  const handleActivationCodeChange = (e) => {
    setActivationCode(e.target.value);
  };

  const verifyActivationCode = () => {
    api
      .post(`public/verifyactivationcode/${activationCode}/${id}`)
      .then((response) => {
        if (response.data.isValid) {
          navigateToSignUpPage();
        } else {
          setActivationError("Invalid activation code. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying activation code:", error);
        setActivationError("An error occurred. Please try again later.");
      });
  };

  const navigateToSignUpPage = () => {
    navigate(`/activate/${id}/${activationCode}/${profile.id}`);
  };

  const getUser = () => {
    api.fetch(`public/PublicUser/${id}`).then((r) => {
      if (r.data && r.data !== user) {
        setUser(r.data);
      }
    });
  };

  const getProfileTheme = () => {
    console.log(profileNumber, "PROFILENUMBER");
    if (parseInt(profileNumber) == 0) {
      let url = `public/PublicUserCompanyTheme/${id}`;
      api.fetch(url).then((r) => {
        if (r.data) {
          console.log("WE IN THE Company ONE");
          setProfile(r.data);
          setPrimaryColor(r.data.primaryColor);
          setSecondaryColor(r.data.secondaryColor);
          setHighlightColor(r.data.highlightColor);
          setBottomColor(r.data.bottomColor);
          setCompanyPanelColor(r.data.companyPanelColor);
          setUseWhiteText(r.data.useWhiteText);
          setNamePanelColor(r.data.namePanelColor);
          const userCompanyProfileIdToSet = r.data.id;
          console.log(userCompanyProfileIdToSet, 'THIS IS WHAT IT SHOULD BE SET TO');
          setUserCompanyProfileId(userCompanyProfileIdToSet);
          getUserCompanyProfileApps(userCompanyProfileIdToSet);
          setProfileName(r.data.profileName);
          setProfileEmail(r.data.profileEmail);
          setProfilePhone(r.data.profilePhone);
          setCompany(r.data.company);
          setTitle(r.data.title);
          getUserArticles(userCompanyProfileIdToSet);
          trackCompanyVisit(userCompanyProfileIdToSet); // Use userCompanyProfileIdToSet directly
          console.log(userCompanyProfileIdToSet, 'THIS IS WHAT IT REALLY WHAT IT SHOULD BE');
        }
      });
    } else {
      let url = `public/PublicUserTheme/${id}`;
      if (profileNumber) {
        url += `/${profileNumber}`;
      }

      api.fetch(url).then((r) => {
        if (r.data) {
          console.log("WE IN THE User ONE");
          setProfile(r.data);
          setPrimaryColor(r.data.primaryColor);
          setSecondaryColor(r.data.secondaryColor);
          setHighlightColor(r.data.highlightColor);
          setBottomColor(r.data.bottomColor);
          setCompanyPanelColor(r.data.companyPanelColor);
          setUseWhiteText(r.data.useWhiteText);
          setNamePanelColor(r.data.namePanelColor);
            const userProfileIdToSet = r.data.id;
            setProfileName(r.data.profileName);
            setProfileEmail(r.data.profileEmail);
            setProfilePhone(r.data.profilePhone);
            setCompany(r.data.company);
            setTitle(r.data.title) 
          setUserProfileId(userProfileIdToSet);
          getUserProfileApps(userProfileIdToSet);
          getUserArticles(userProfileIdToSet);
          trackVisit(userProfileIdToSet);
        }
      });
    }
  };

  const [userProfileArticles, setUserProfileArticles] = useState([
    {
      id: 1,
      article:{
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
        },
      
    display: true,
    },
    {
        id: 2,
        article:{
          title: "Article 1",
          thumbnailUrl: "Thumbnail 2",
          linkUrl: "URL 1",
          description: "Description 2",
          },
        
      display: true,
      },
      {
        id: 3,
        article:{
          title: "Article 1",
          thumbnailUrl: "Thumbnail 2",
          linkUrl: "URL 1",
          description: "Description 2",
          },
        
      display: true,
      },
  ]);




  useEffect(() => {
    getUserArticles();
  }, []);


  useEffect(() => {
    if (!Cookies.get("sessionID")) {
      Cookies.set("sessionID", `sess-${Date.now()}`, { expires: 1 }); 
    }
    console.log(profileNumber, 'PROFILE NUMBER IN USE EFFECT');
    getUser();
    getProfileTheme();
  }, []);

  const getUserArticles = async (userProfileId) => {
    try {
      const response = await api.post(
        `public/CurrentProfileArticleList/${userProfileId}`,
        {}
      );
      if (response.data) {
        setUserProfileArticles(
          Array.isArray(response.data) ? response.data : []
        );

        console.log(response.data, "response");
      }
    } catch (error) {
      console.error("Failed to fetch user articles", error);
    }
  };
  

  const getUserProfileApps = async (userProfileId) => {
    try {
      const response = await api.post(
        `public/CurrentUserProfileAppList/${userProfileId}`,
        {}
      );
      if (response.data) {
        const businessApps = response.data.filter(
          (item) => item.app.appGroupId === 1
        );
        const paymentApps = response.data.filter(
          (item) => item.app.appGroupId === 2
        );
        const socialApps = response.data.filter(
          (item) => item.app.appGroupId === 3
        );
        const customApps = response.data.filter(
          (item) => item.app.appGroupId === 4
        );
        setBusinessApps(businessApps);
        setPaymentApps(paymentApps);
        setSocialApps(socialApps);
        setCustomApps(customApps);
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
  };

  const getUserCompanyProfileApps = async (userCompanyProfileId) => {
    try {
      const response = await api.post(
        `public/CurrentUserCompanyProfileAppList/${userCompanyProfileId}`,
        {}
      );

      if (response.data) {
        console.log(response.data, "response");

        const businessApps = response.data.filter(
          (item) =>
            item.companyApp &&
            item.companyApp.app &&
            item.companyApp.app.appGroupId === 1
        );
        console.log(businessApps, "businessApps");

        const paymentApps = response.data.filter(
          (item) =>
            item.companyApp &&
            item.companyApp.app &&
            item.companyApp.app.appGroupId === 2
        );
        console.log(paymentApps, "paymentApps");

        const socialApps = response.data.filter(
          (item) =>
            item.companyApp &&
            item.companyApp.app &&
            item.companyApp.app.appGroupId === 3
        );
        console.log(socialApps, "socialApps");

        const customApps = response.data.filter(
          (item) =>
            item.companyApp &&
            item.companyApp.app &&
            item.companyApp.app.appGroupId === 4
        );
        console.log(customApps, "customApps");

        setBusinessApps(businessApps);
        setPaymentApps(paymentApps);
        setSocialApps(socialApps);
        setCustomApps(customApps);
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
  };

  useEffect(() => {
    if (updateProfile) {
      // Fetch and update data here if needed

      // Reset the updateProfile state to trigger a re-render
      setUpdateProfile(false);
    }
  }, [updateProfile]);

  useEffect(() => {
    setUser(userCtx.currentUser);
  }, [userCtx.currentUser]);

  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };


  const handleShare = async () => {
    if (isSaving) return;
    setIsSaving(true);
    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: leadEmail,
      company: leadCompany,
      mobilePhoneNumber: leadPhoneNumber,
      userId: id,
      profileNumber:profileNumber
    };
    console.log(payload,'PAYHLOAD'
    )
    api
      .post("public/SaveLead", payload)
      .then((res) => {
        if (res.data.success) {
          // Close the modal after sharing
          toggleModal();
        }
      })
      .catch((err) => {
        api.catchHandler(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };


  const handleDownload = async () => {
    try {
      // Wait for the user card data to be fetched
      //const userCard = await getUserCard();

      const vcard = new vCard();
      // Set properties for the vCard using userCard data
      //vcard.add("fn", `${userCard.firstName} ${userCard.lastName}`);
      vcard.add("n", [profileName, "", "", ""]);
      vcard.add("tel", profilePhone, { type: ["cell"] });
        vcard.add("email", profileEmail);
      vcard.add("org", company);
      vcard.add("url", `www.nexx.co/profile/${id}/${profileNumber}`);
      const vCardData = vcard.toString();
      const encodedVCardData = encodeURIComponent(vCardData);
        window.location.href = `data:text/vcard;charset=utf-8,${encodedVCardData}`;
      console.log(vcard, 'VCARD DATA')
      setShowModal(true);
      setShowModal(true);
    } catch (error) {
      console.error("Error:", error);
      // Optionally, handle the error more visibly to the user
    }
  };



  useEffect(() => {
    if (useWhiteText === true) {
      setTextColor("#fff");
    } else {
      setTextColor("#000");
    }
  }, [useWhiteText]);



  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const formattedValue = formatPhoneNumber(inputValue);
    setLeadPhoneNumber(formattedValue); // Always update the formatted value
    if (inputValue.length === 10 || inputValue.length === 0) {
      setPhoneNumberError(""); // Clear error message if length is valid
    } else {
      setPhoneNumberError("Phone number must be 10 digits.");
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1)-$2-$3"
    );
    return formattedPhoneNumber;
  };

  return (
    <>
      {profile && !profile.activatedAt && (
        <Modal
          open={true}
          onClose={() => {}} // Handle closing if needed
          aria-labelledby="activation-code-modal"
          aria-describedby="activation-code-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Please enter the activation code
            </Typography>
            <TextField
              label="Activation Code"
              variant="outlined"
              value={activationCode}
              onChange={handleActivationCodeChange}
              fullWidth
              margin="normal"
              error={!!activationError}
              helperText={activationError}
            />
            <Button variant="contained" onClick={verifyActivationCode}>
              Verify
            </Button>
          </Box>
        </Modal>
      )}
      <ProfileLayout
        useWhiteText={useWhiteText}
        colorInvert={true}
        handleDownload={handleDownload}
      >
        <Box
          style={{
            background: bottomColor,
            fill: bottomColor,
            minHeight: "100vh",  // Full viewport height for main container
          }}
          position={"relative"}
        >
          {/* Upper Section */}
          <Box
            style={{
              background: `linear-gradient(180deg, ${primaryColor} 0%,  ${secondaryColor} 100%)`,
            }}
            position={"relative"}
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginTop: -13,
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            <Container
              zIndex={3}
              position={"relative"}
              minHeight={{ xs: 300, sm: 300, md: 500 }}
              maxHeight={500}
              display={"flex"}
              alignItems={"flex-center"}
              justifyContent={"center"}
              sx={{ paddingBottom: 13, alignItems: "center" }}
            >
              <Box sx={{ alignItems: "center" }}>
                {user && user.photoUrl && (
                  <Avatar
                    alt="User Avatar"
                    variant="rounded"
                    src={user.photoUrl}
                    sx={{
                      borderRadius: "30px",
                      width: { xs: "10em", md: "17em" },
                      height: { xs: "10em", md: "17em" },
                    }}
                  />
                )}
              </Box>
            </Container>
            <Box
              component={"svg"}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              maxHeight={120}
              bottom={0}
              position={"absolute"}
              zIndex={2}
            >
              <path
                style={{ background: secondaryColor, fill: secondaryColor }}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>

          <Container
            sx={{
              marginTop: "-4rem",
              position: "relative",
              zIndex: 3,
              paddingY: "0 !important",
            }}
          >
            <Box>
              <Box
                style={{
                  background: namePanelColor,
                  border: `4px solid  ${highlightColor}`,
                }}
                padding={2}
                width={1}
                component={Card}
                boxShadow={4}
                marginBottom={3}
              >
                <Box display="flex" alignItems={"center"}>
                  <Box marginTop={"0 !important"}></Box>
                  <Box
                    width={1}
                    marginRight={1}
                    sx={{ textAlign: "center", padding: 0, margin: 0 }}
                  >
                    <Tooltip
                      title="Download contact information"
                      sx={{ color: "text.primary" }}
                      arrow
                    ></Tooltip>

                    <Typography
                      variant="h3"
                      color={textColor}
                      sx={{ fontWeight: 700, textAlign: "center" }}
                    >
                      {profileName}
                    </Typography>
                  </Box>
                  <Box marginTop={"0 !important"}></Box>
                </Box>
              </Box>
              <Box
                style={{
                  background: companyPanelColor,
                  border: `4px solid  ${highlightColor}`,
                }}
                padding={2}
                width={1}
                component={Card}
                boxShadow={4}
                marginBottom={3}
              >
                <Box display="flex" alignItems={"center"}>
                  <Box
                    width={1}
                    marginRight={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      color={textColor}
                      sx={{ fontWeight: 700, textAlign: "center" }}
                    >
                      {/* {"NEXX Cards"} - {"CFO"} */}
                      {company} - {title}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {customApps.length === 0 ? (
                <div></div>
              ) : (
                <Box>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      paddingBottom: 3,
                      marginTop: 5,
                    }}
                  >
                    Personal
                  </Typography>
                  <Grid container spacing={2}>
                    {customApps.length == 0 ? (
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        sx={{ textAlign: "center", marginBottom: "1em" }}
                      >
                        <AppPlaceholder />
                      </Grid>
                    ) : (
                      customApps.map((item, index) => (
                        <Grid
                          item
                          key={index}
                          xs={3}
                          sm={3}
                          md={3}
                          sx={{ textAlign: "center", marginBottom: "1em" }}
                        >
                          <>
                            <Box
                              component="img"
                              sx={{
                                height: "5rem",
                                width: "5rem",
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                                cursor: "pointer",
                              }}
                              alt="Custom App"
                              // src={item.companyApp.app.iconUrl}
                              onClick={(e) => {
                                trackLinkClick(item.id, e);
                                handleButtonClick(
                                  `${item.companyApp.app.linkPrefix}${item.linkSufix}`
                                );
                              }}
                            />
                            <Typography
                              variant="subtitle1"
                              color={textColor}
                              sx={{ marginTop: "0" }}
                            >
                              {console.log(item, "ITEM HERE< FIGUIRE OUT")}
                              {item.customAppName}
                            </Typography>
                          </>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Box>
              )}
             <Box>
  {businessApps.length > 0 ? (
    <>
      <Typography
        variant="h5"
        color={textColor}
        sx={{
          fontWeight: 700,
          textAlign: "center",
          paddingBottom: 3,
        }}
      >
        Business
      </Typography>
      <Grid container spacing={2}>
        {businessApps.map((item, index) => (
          <Grid
            item
            key={index}
            xs={3}
            sm={3}
            md={3}
            sx={{ textAlign: "center", marginBottom: "1em" }}
          >
            {profileNumber == 0 ? (
              <>
                <Box
                  component="img"
                  sx={{
                    height: "5rem",
                    width: "5rem",
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    cursor: "pointer",
                  }}
                  alt="Business App"
                  src={item.companyApp.app.iconUrl}
                  onClick={(e) => {
                    trackLinkClick(item.id, e);
                    handleButtonClick(
                      `${item.companyApp.app.linkPrefix}${item.linkSufix}`
                    );
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={textColor}
                  sx={{ marginTop: "0" }}
                >
                  {item.companyApp.app.name}
                </Typography>
              </>
            ) : (
              <>
                <Box
                  component="img"
                  sx={{
                    height: "5rem",
                    width: "5rem",
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    cursor: "pointer",
                  }}
                  alt="Business App"
                  src={item.app.iconUrl}
                  onClick={(e) => {
                    trackLinkClick(item.id, e);
                    handleButtonClick(
                      `${item.app.linkPrefix}${item.linkSufix}`
                    );
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={textColor}
                  sx={{ marginTop: "0" }}
                >
                  {item.app.name}
                </Typography>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <Grid
      item
      xs={3}
      sm={3}
      md={3}
      sx={{ textAlign: "center", marginBottom: "1em" }}
    >
      {/* <AppPlaceholder /> */}
    </Grid>
  )}
</Box>
              {paymentApps.length > 0 && (
  <Box>
    <Typography
      variant="h5"
      color={textColor}
      sx={{
        fontWeight: 700,
        textAlign: "center",
        paddingBottom: 3,
      }}
    >
      Payment
    </Typography>
    <Grid container spacing={2}>
      {paymentApps.map((item, index) => (
        <Grid
          item
          key={index}
          xs={3}
          sm={3}
          md={3}
          sx={{ textAlign: "center", marginBottom: "1em" }}
        >
          {profileNumber == 0 ? (
            <>
              <Box
                component="img"
                sx={{
                  height: "5rem",
                  width: "5rem",
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  cursor: "pointer",
                }}
                alt="Payment App"
                src={item.companyApp.app.iconUrl}
                onClick={(e) => {
                  trackLinkClick(item.id, e);
                  handleButtonClick(
                    `${item.companyApp.app.linkPrefix}${item.linkSufix}`
                  );
                }}
              />
              <Typography
                variant="subtitle1"
                color={textColor}
                sx={{ marginTop: "0" }}
              >
                {item.companyApp.app.name}
              </Typography>
            </>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  height: "5rem",
                  width: "5rem",
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  cursor: "pointer",
                }}
                alt="Social App"
                src={item.app.iconUrl}
                onClick={(e) => {
                  trackLinkClick(item.id, e);
                  handleButtonClick(
                    `${item.app.linkPrefix}${item.linkSufix}`
                  );
                }}
              />
              <Typography
                variant="subtitle1"
                color={textColor}
                sx={{ marginTop: "0" }}
              >
                {item.app.name}
              </Typography>
            </>
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
)}
            {socialApps.length > 0 && (
  <Box>
    <Typography
      variant="h5"
      color={textColor}
      sx={{
        fontWeight: 700,
        textAlign: "center",
        paddingBottom: 3,
      }}
    >
      Social
    </Typography>
    <Grid container spacing={2}>
      {socialApps.map((item, index) => (
        <Grid
          item
          key={index}
          xs={3}
          sm={3}
          md={3}
          sx={{ textAlign: "center", marginBottom: "1em" }}
        >
          {profileNumber == 0 ? (
            <>
              <Box
                component="img"
                sx={{
                  height: "5rem",
                  width: "5rem",
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  cursor: "pointer",
                }}
                alt="Social App"
                src={item.companyApp.app.iconUrl}
                onClick={(e) => {
                  trackLinkClick(item.id, e);
                  handleButtonClick(
                    `${item.companyApp.app.linkPrefix}${item.linkSufix}`
                  );
                }}
              />
              <Typography
                variant="subtitle1"
                color={textColor}
                sx={{ marginTop: "0" }}
              >
                {item.companyApp.app.name}
              </Typography>
            </>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  height: "5rem",
                  width: "5rem",
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  cursor: "pointer",
                }}
                alt="Social App"
                src={item.app.iconUrl}
                onClick={(e) => {
                  trackLinkClick(item.id, e);
                  handleButtonClick(
                    `${item.app.linkPrefix}${item.linkSufix}`
                  );
                }}
              />
              <Typography
                variant="subtitle1"
                color={textColor}
                sx={{ marginTop: "0" }}
              >
                {item.app.name}
              </Typography>
            </>
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
)}
              <Box>
                <Typography
                  variant="h5"
                  color={textColor}
                  sx={{
                    fontWeight: 700,
                    textAlign: "center",
                    paddingBottom: 1,
                  }}
                >
                  Featured Content
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  marginBottom={4}
                ></Box>
                <Grid container spacing={4}>
                  {userProfileArticles.map((item, i) => (
                    <Grid marginBottom={4} item xs={12} sm={6} md={4} key={i}>
                      <Box
                        component={"a"}
                        href={""}
                        display={"block"}
                        width={1}
                        height={1}
                        sx={{
                          textDecoration: "none",
                          transition: "all .2s ease-in-out",
                          "&:hover": {
                            transform: `translateY(-${theme.spacing(1 / 2)})`,
                          },
                        }}
                      >
                        <Box
                          component={Card}
                          width={1}
                          height={1}
                          boxShadow={4}
                          display={"flex"}
                          flexDirection={"column"}
                          sx={{ backgroundImage: "none" }}
                        >
                          <CardMedia
                            image={item.article.thumbnailUrl}
                            title={item.article.title}
                            sx={{
                              height: { xs: 300, md: 360 },
                              position: "relative",
                            }}
                          >
                            <Box
                              component={"svg"}
                              viewBox="0 0 2880 480"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                color: "#000",
                                transform: "scale(2)",
                                height: "auto",
                                width: 1,
                                transformOrigin: "top center",
                              }}
                            >
                              <path
                                // color={primaryColor }
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                                fill={companyPanelColor}
                              />
                            </Box>
                          </CardMedia>
                          <Box
                            sx={{ backgroundColor: companyPanelColor }}
                            component={CardContent}
                            position={"relative"}
                          >
                            <Typography variant={"h6"} gutterBottom>
                              {item.article.title}
                            </Typography>
                            <Typography>{item.article.description}</Typography>
                          </Box>
                          <Box
                            sx={{ backgroundColor: companyPanelColor }}
                            flexGrow={1}
                          />
                          <Box
                            sx={{ backgroundColor: companyPanelColor }}
                            padding={2}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Box marginBottom={2}>
                              <Divider
                                sx={{
                                  height: "3px",
                                  backgroundColor: highlightColor,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    background: "#111",
                    position: "absolute",
                    width: "70%",
                    border: "2px solid #000",
                    p: 2,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Share Your Information Back!
                  </Typography>
                  <form noValidate autoComplete="off">
                    <TextField
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Phone Number"
                      value={leadPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 10 }} // Limit input to 10 characters
                      error={phoneNumberError.length > 0}
                      helperText={phoneNumberError}
                    />
                    <TextField
                      label="Email"
                      value={leadEmail}
                      onChange={(e) => setLeadEmail(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Company"
                      value={leadCompany}
                      onChange={(e) => setLeadCompany(e.target.value)}
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 25 }}
                    />
                    <Button
                      style={{
                        background: primaryColor,
                        border: `2px solid  ${highlightColor}`,
                      }}
                      variant="contained"
                      onClick={handleShare}
                    >
                      Share
                    </Button>
                  </form>
                </Box>
              </Modal>
            </Box>
          </Container>
        </Box>
      </ProfileLayout>
    </>
  );
}

import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import QRCodeStyling, {} from "qr-code-styling";
import {
    Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
    FormControl,
  Box
} from "@mui/material";
import { api, helpers, UserContext, constants } from "../utils";
import { NexxLogoBlack } from "../assets/images";

const dotStyleList = [
    { name: "Square", value: "square" },
    { name: "Dots", value: "dots" },
    { name: "Rounded", value: "rounded" },
    { name: "Extra Rounded", value: "extra-rounded" },
    { name: "Classy", value: "classy" },
    { name: "Classy Rounded", value: "classy-rounded" },
];
const cornerSquareStyleList = [
    { label: "None", value: "" },
    { label: "Square", value: "square" },
    { label: "Dot", value: "dot" },
    { label: "Extra Rounded", value: "extra-rounded" },
];
const cornerDotStyleList = [
    { label: "None", value: "" },
    { label: "Square", value: "square" },
    { label: "Dot", value: "dot" },
];
// const errorCorrectionLevels = [
//   { label: "Low", value: 1},
//   { label: "Medium", value: 2},
//   { label: "High", value: 3 },
//   { label: "Ultra", value: 4 },
// ];

export default function QrEdit() {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const [url, setUrl] = useState("www.test.com");
  const [qrcWidth, setQrcWidth] = useState("250");
  const [qrcHeight, setQrcHeight] = useState("250");
    const [dotOptionsType, setDotOptionsType] = useState({});
  const [dotOptionsColor, setDotOptionsColor] = useState("#FF0000");
    const [cornersSquareOptionsType, setCornersSquareOptionsType] = useState({});
  const [cornersSquareColor, setcornersSquareColor] = useState("#FF0000");
    const [cornersDotType, setCornersDotOptionsType] = useState({});
  const [cornersDotColor, setCornersDotOptionsColor] = useState("#FF0000");
  // const [errorCorrectionLevel, setErrorCorrectionLevel] = useState(1);
  const [message, setMessage] = useState(null);
    const [logoSize, setLogoSize] = useState("0.25");

    const getUser = () =>
    api.fetch(`UserAdmin/User/${user.id}`).then((r) => {
      if (r.data) {
        setUser(r.data);
      }
    });

    const getQrCode = () =>
        api.fetch(`profile/QRCode/${user.id}`).then((r) => {
            if (r.data) {
                setDotOptionsType(r.data.dotType);
                setUrl(r.data.linkUrl);
                setDotOptionsColor(r.data.dotColor);
                setCornersSquareOptionsType(r.data.cornerType);
                setcornersSquareColor(r.data.cornerColor);
                setCornersDotOptionsType(r.data.cornerDotType);
                setCornersDotOptionsColor(r.data.cornerDotColor);
                // setErrorCorrectionLevel(2);
            }
        }
    );

    useEffect(() => {
      getUser();
      getQrCode();
    }, []);

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    data: url,
    margin: 1,
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevelId: 2,
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: logoSize,
      margin: 4,
    },
    dotsOptions: {
      type: dotOptionsType,
      color: dotOptionsColor,
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    image: NexxLogoBlack,
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        // color1: "#6a1a4c",
        // color2: "#6a1a4c",
        // rotation: "0",
      },
    },
    cornersSquareOptions: {
      type: cornersSquareOptionsType,
      color: cornersSquareColor,
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        // color1: "#000000",
        // color2: "#000000",
        // rotation: "0",
      },
    },
    cornersDotOptions: {
      type: cornersDotType,
      color: cornersDotColor,
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        // color1: "#000000",
        // color2: "#000000",
        // rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        // color1: "#ffffff",
        // color2: "#ffffff",
        // rotation: "0",
      },
    },
  });

   function saveUserQRCode() {
   let payload = {
       userId: user.id,
       linkUrl: _.trim(url),
       dotType: dotOptionsType,
       dotColor: _.trim(dotOptionsColor),
       cornerType: cornersSquareOptionsType,
       cornerColor: _.trim(cornersSquareColor),
       cornerDotType: cornersDotType,
       cornerDotColor: _.trim(cornersDotColor),
       errorCorrectionLevel: 'medium',
       logoSize:logoSize
   }
   api.post(`Profile/SaveUserQRCode/${user.id}`, payload)
       .then(response => {
           if (response.data.success) {
               
           } else {
               setMessage({ text: response.data.message, flavor: "alert-danger" });
           }
       }).catch(helpers.catchHandler);
   }

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      dotOptions: {
        type: dotOptionsType,
        color: dotOptionsColor,
      },
    });
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  useEffect(() => {
    qrCode.update({
      dotOptions: {
        type: dotOptionsType,
        color: dotOptionsColor,
      },
    });
  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      cornersSquareOptions: {
        type: cornersSquareOptionsType,
        color: cornersSquareColor,
      },
    });
  }, [cornersSquareOptionsType, cornersSquareColor]);

  useEffect(() => {
    qrCode.update({
      cornersDotOptions: {
        type: cornersDotType,
        color: cornersDotColor,
      },
    });
  }, [cornersDotType, cornersDotColor]);

  // useEffect(() => {
  //   qrCode.update({
  //     qrOptions: {
  //       errorCorrectionLevel: 2,
  //     },
  //   });
  // }, [errorCorrectionLevel]);

  useEffect(() => {
    if (qrcWidth > 150) {
      qrCode.update({
        width: qrcWidth,
      });
    }
  }, [qrcWidth]);

  useEffect(() => {
    if (qrcHeight > 150) {
      qrCode.update({
        height: qrcHeight,
      });
    }
  }, [qrcHeight]);

  

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };
  const onDotTypeChange = (event) => {
    event.preventDefault();
    setDotOptionsType(event.target.value);
  };
  const onDotColorChange = (event) => {
    event.preventDefault();
    setDotOptionsColor(event.target.value);
  };
  const onCornerSquareTypeChange = (event) => {
    event.preventDefault();
    setCornersSquareOptionsType(event.target.value);
  };
  const onCornerSquareColorChange = (event) => {
    event.preventDefault();
    setcornersSquareColor(event.target.value);
  };
  const onCornerDotTypeChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsType(event.target.value);
  };
  const onCornerDotColorChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsColor(event.target.value);
  };
  // const onErrorCorrectionLevelChange = (event) => {
  //   event.preventDefault();
  //   setErrorCorrectionLevel(event.target.value);
  // };

  const onWidthChange = (event) => {
    event.preventDefault();
    setQrcWidth(event.target.value);
  };

  const onHeightChange = (event) => {
    event.preventDefault();
    setQrcHeight(event.target.value);
  };

  const onLogoChange = (event) => {
    event.preventDefault();
    setLogoSize(event.target.value);
  };

  return (
    <div className="App">
      <div style={styles.flexContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
          }}
          ref={ref}
        />
        {/* <TextField
          label="URL"
          value={url}
          onChange={onUrlChange}
          sx={{
            width: "100%",
            '& .MuiOutlinedInput-root': {
              color: 'white', // Input text color
              '& fieldset': {
                borderColor: 'white', // Border color
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white', // Label color when focused
            },
          }}
          style={styles.inputBox}
        /> */}
      </div>
  
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 20,
        }}
      >
        <FormControl sx={{ width: "60%" }} style={styles.formControl}>
  <InputLabel sx={{ color: 'white' }}>Dot Type</InputLabel>
  <Select
    value={dotOptionsType}
    onChange={onDotTypeChange}
    sx={{
      color: 'white', // Text color
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', // Border color
      },
      '& .MuiSvgIcon-root': {
        color: 'white', // Dropdown arrow color
      },
      '& .MuiSelect-select': {
        backgroundColor: 'primary.text', // Background color for the selected item displayed in the dropdown input
      },
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          bgcolor: 'text.primary', // Background color for the dropdown list
          '& .MuiMenuItem-root': {
            color: 'white',
            '&.Mui-selected': {
              bgcolor: 'blue', // Background color for the selected item in the list
            },
            '&.Mui-selected:hover': {
              bgcolor: 'darkblue', // Background color for the selected item when hovered
            },
          },
        },
      },
    }}
  >
    {dotStyleList.map((style) => (
      <MenuItem
        key={style.name}
        value={style.value}
        sx={{
          color: 'white', // Text color for all items
        }}
      >
        {style.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>
        <TextField
          label="Dot Color"
          type="color"
          value={dotOptionsColor}
          onChange={onDotColorChange}
          sx={{
            width: "30%",
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white',
            },
          }}
        />
      </div>
  
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <FormControl sx={{ width: "60%" }} style={styles.formControl}>
          <InputLabel sx={{ color: 'white' }}>Corner Type</InputLabel>
          <Select
            value={cornersSquareOptionsType}
            onChange={onCornerSquareTypeChange}
            sx={{
              color: 'white', // Text color
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray', // Border color
              },
              '& .MuiSvgIcon-root': {
                color: 'white', // Dropdown arrow color
              },
              '& .MuiSelect-select': {
                backgroundColor: 'primary.text', // Background color for the selected item displayed in the dropdown input
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'text.primary', // Background color for the dropdown list
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    '&.Mui-selected': {
                      bgcolor: 'blue', // Background color for the selected item in the list
                    },
                    '&.Mui-selected:hover': {
                      bgcolor: 'darkblue', // Background color for the selected item when hovered
                    },
                  },
                },
              },
            }}
          >
            {cornerSquareStyleList.map((style) => (
              <MenuItem
                key={style.label}
                value={style.value}
                sx={{ color: 'white' }} // Menu item text color
              >
                {style.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Corner Color"
          type="color"
          value={cornersSquareColor}
          onChange={onCornerSquareColorChange}
          sx={{
            width: "30%",
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white',
            },
          }}
        />
      </div>
  
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl sx={{ width: "60%" }} style={styles.formControl}>
          <InputLabel sx={{ color: 'white' }}>Corner Dot Type</InputLabel>
          <Select
            value={cornersDotType}
            onChange={onCornerDotTypeChange}
            sx={{
              color: 'white', // Text color
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray', // Border color
              },
              '& .MuiSvgIcon-root': {
                color: 'white', // Dropdown arrow color
              },
              '& .MuiSelect-select': {
                backgroundColor: 'primary.text', // Background color for the selected item displayed in the dropdown input
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'text.primary', // Background color for the dropdown list
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    '&.Mui-selected': {
                      bgcolor: 'blue', // Background color for the selected item in the list
                    },
                    '&.Mui-selected:hover': {
                      bgcolor: 'darkblue', // Background color for the selected item when hovered
                    },
                  },
                },
              },
            }}
          >
            {cornerDotStyleList.map((style) => (
              <MenuItem
                key={style.label}
                value={style.value}
                sx={{ color: 'white' }} // Menu item text color
              >
                {style.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Dot Color"
          type="color"
          value={cornersDotColor}
          onChange={onCornerDotColorChange}
          sx={{
            width: "30%",
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white',
            },
          }}
        />
      </div>
  
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                    justifyContent="flex-end" 
                >
                    <Button
                        component={'a'}
                        variant="contained"
                        color="customColor"
                        size="large"
                        onClick={saveUserQRCode}
                    >
                        SAVE QR CODE
                    </Button>
                </Box>
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
};
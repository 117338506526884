import React, { useContext } from "react";
import PropTypes from 'prop-types';
import NavItem from './components/NavItem';
import { UserContext } from "../../../../../../utils";
import { Box, Button, Typography, alpha } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { NexxLogoBlack } from "../../../../../../assets/images";

const SidebarNav = ({ onSidebarOpen, colorInvert = false }) => {
  const userCtx = useContext(UserContext);
  const handleSignout = userCtx.signOut;

  const pages = {
    company: [
      {
        title: "Customer Stories",
        href: "/customers",
      },
      {
        title: "About: Startup",
        href: "/about-side-cover",
      },
      {
        title: "Terms",
        href: "/company-terms",
      },
      {
        title: "Contact: Minimal",
        href: "/contact-page-cover",
      },
    ],
    secondary: [
      {
        title: "Pricing",
        href: "/pricing",
      },
      {
        title: "FAQ",
        href: "/faq",
      },
      {
        title: "Purchase",
        href: "https://6fe5d4-2c.myshopify.com/",
      },
    ],
    account: [
      {
        title: "Profile Theme",
        href: "/account/theme",
        subItems: [
          {
            id: "profileTheme",
            href: "/account/theme",
            title: "Profile Styling",
          },
          {
            id: "profileArticles",
            href: "/account/theme/articles",
            title: "Profile Articles",
          },
          {
            id: "qrcode",
            href: "/account/qrcode",
            title: "Profile QR Code",
          },

          {
            id: "leads",
            href: "/leads",
            title: "View Leads",
          },
        ],
      },
      {
        title: "Company Profile Theme",
        href: "/account/company/theme",
      },
      {
        title: "General Settings",
        href: "/account/general",
      },
      {
        title: "Billing Options",
        href: "/account/qrcode",
      },
      {
        title: "Change Profile Photo",
        href: "/change-profile-photo",
      },
      {
        title: "Wallet",
        href: "/wallet",
      },
      {
        title: "Sign Out",
        onClick: (e) => {
          e.preventDefault();
          handleSignout();
        },
      },
    ],
    portfolio: [
      {
        title: "Basic",
        href: "/portfolio-page",
      },
    ],
  };

  const theme = useTheme();

  const {
    company: companyPages,
    secondary: secondaryPages,
    account: accountPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingX={2}
        paddingY={1}
      >
        <Box
          display={'flex'}
          component="a"
          href="/"
          title=" "
          width={{ xs: 100, md: 120 }}
        >
          <Box component={"img"} src={NexxLogoBlack} height={1} width={1} />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={"outlined"}
            sx={{
              borderRadius: 2,
              fill: "#fff",
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Home'} items={[{ title: "Home", href: "/" }]} />
        </Box>
        <Box>
          <NavItem title={'Company & Clients'} items={companyPages} colorInvert={colorInvert} />
        </Box>
        <Box>
          <NavItem title={'Shop'} items={secondaryPages} colorInvert={colorInvert} />
        </Box>
        <Box>
          <NavItem title={'My Account'} items={accountPages} colorInvert={colorInvert} />
        </Box>
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://6fe5d4-2c.myshopify.com/"
          >
            Purchase now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default SidebarNav;
/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import { default as QrEdit } from "./QrEdit";
import { api, helpers, UserContext, constants } from "../utils";
import _, { isNil } from "lodash";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import UserApps from "./UserApps";
import {
  Snackbar,
  Alert,
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { text } from "@fortawesome/fontawesome-svg-core";
import ColorPickerComponent from "./ColorPickerComponent";
import Qr from "./Qr";

const Square = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "15em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(3),
  display: "inline-block", // Make squares display inline
}));
const Rectangle = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "4em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "inline-block", // Make squares display inline
}));

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};
const Color1 = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const Color2 = styled("div")(() => ({
  position: "relative",
  width: "95%",
  height: "2rem",
}));

const calculateLast7Days = () => {
  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);

  return {
    startDate: last7Days.toISOString().slice(0, 10),
    endDate: today.toISOString().slice(0, 10),
  };
};

const FullPageWrapper = styled("div")(() => ({
  minHeight: "100vh", // Full height of the viewport
  // backgroundColor: "#006d71", // Your desired background color (the blue from the screenshot)
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CompanyTheme = ({}) => {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const { startDate, endDate } = calculateLast7Days();
  const [currentStartDate, setCurrentStartDate] = useState(startDate);
  const [currentEndDate, setCurrentEndDate] = useState(endDate);
  const { id } = useParams();
  const [userApps, setUserApps] = useState([]);
  const [companyApps, setCompanyApps] = useState([]);
  const [profileNumber, setProfileNumber] = useState(1);
  const [userId, setUserId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [profileList, setProfileList] = useState([]);
  const [userProfileId, setUserProfileId] = useState(5);
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#444");
  const [highlightColor, setHighlightColor] = useState("#111");
  const [bottomColor, setBottomColor] = useState("#111");
  const [namePanelColor, setNamePanelColor] = useState("#111");
  const [companyPanelColor, setCompanyPanelColor] = useState("#111");
  const [requireContactCode, setRequireContactCode] = useState(false);
  const [requireProfileCode, setRequireProfileCode] = useState(false);
  const [profileCode, setProfileCode] = useState("");
  const [company, setCompany] = useState("");
  const [profile, setProfile] = useState();
  const [companyProfileId, setCompanyProfileId] = useState();
  const [contactCode, setContactCode] = useState("");
  const [message, setMessage] = useState(null);
  const [useWhiteText, setUseWhiteText] = useState(true);
  const navigate = useNavigate();
  const [showAllBusinessApps, setShowAllBusinessApps] = useState(false);
  const [showAllMoneyApps, setShowAllMoneyApps] = useState(false);
  const [showAllSocialApps, setShowAllSocialApps] = useState(false);
  const [showAllCustomApps, setShowAllCustomApps] = useState(false);
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const [customApps, setCustomApps] = useState([]);
  const [allBusinessApps, setAllBusinessApps] = useState([]);
  const [allPaymentApps, setAllPaymentApps] = useState([]);
  const [allSocialApps, setAllSocialApps] = useState([]);
  const [allCustomApps, setAllCustomApps] = useState([]);
  const [commonBusinessAppIds, setCommonBusinessAppIds] = useState([]);
  const [commonPaymentAppIds, setCommonPaymentAppIds] = useState([]);
  const [commonSocialAppIds, setCommonSocialAppIds] = useState([]);
  const [commonCustomAppIds, setCommonCustomAppIds] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [profileVisits, setProfileVisits] = useState({});

  const handleDateChange = (event) => {
    if (event.target.name === "startDate") {
      setCurrentStartDate(event.target.value);
    } else if (event.target.name === "endDate") {
      setCurrentEndDate(event.target.value);
    }
  };

  const handleColorChange = (setter) => (value) => {
    if (typeof value === "string") {
      setter(value);
    } else {
      setter(value.target.value);
    }
  };

  const fetchProductLink = async () => {
    try {
      console.log("UserID before sending:", userId);
      const response = await api.post(
        "userAdmin/generate-link",
        { UserId: user.Id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Product Link:", response);
      window.location.href = response.data;
    } catch (error) {
      console.error("Error generating product link:", error);
    }
  };

  const showMessage = (text, type) => {
    setMessage({ text, flavor: type });
    setOpenSnackbar(true); 
  };
  function getCompanyAppsPayload() {
    const allApps = [
      ...businessApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        customAppName: "",
        name: app.name,
        linkSufix: app.linkSufix,
      })),
      ...paymentApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        customAppName: "",
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...socialApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        customAppName: "",
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...customApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        customAppName: app.customAppName,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
    ];
    return allApps;
  }


  function assignUserId() {
    setUserId(user.id);
  }
  

  const saveProfileThemeAndApps = () => {
    const allApps = getCompanyAppsPayload(); 
    let themePayload = {
      companyId: companyId,
      primaryColor: _.trim(primaryColor),
      secondaryColor: _.trim(secondaryColor),
      highlightColor: _.trim(highlightColor),
      bottomColor: _.trim(bottomColor),
      namePanelColor: _.trim(namePanelColor),
      companyPanelColor: _.trim(companyPanelColor),
      useWhiteText,
      apps: allApps,
    };

    api
      .post(`company/SaveCompanyProfileTheme/${companyId}`, themePayload)
      .then((response) => {
        if (response.data.success) {
          showMessage("Profile Theme Update Was Successful", "alert-success");
        } else {
          showMessage(
            "There was an error saving your profile theme, please refresh the page and try again.",
            "alert-danger"
          );
        }
      })
      .catch(helpers.catchHandler);
  };

  const fetchCompanyId = async () => {
    try {
      console.log("Fetching companyId with userId:", id);
      const response = await api.get(`userAdmin/UserTheme/${id}`);
      
      if (response && response.data) {
        const profile = response.data; // Assuming the API response has the profile data
        setCompanyId(profile.companyProfile?.Company?.Id || 0); // Fetch and set companyId
        console.log("Fetched companyId:", profile.companyProfile?.Company?.Id);
      }
    } catch (error) {
      console.error("Error fetching companyId:", error);
      setMessage("Error fetching companyId.");
    }
  };

 

  const getProfileColorsAndApps = async () => {
    try {
      const response = await api.fetch(`company/CompanyTheme/${1}`);
      if (response.data) {
        setPrimaryColor(response.data.primaryColor);
        setSecondaryColor(response.data.secondaryColor);
        setHighlightColor(response.data.highlightColor);
        setBottomColor(response.data.bottomColor);
        setCompanyPanelColor(response.data.companyPanelColor);
        setNamePanelColor(response.data.namePanelColor);
        setCompanyProfileId(response.data.id);
        setProfile(response.data);

        const appsResponse = await api.post(
          `company/CurrentCompanyProfileAppList/${response.data.id}`
        );
        if (appsResponse.data) {
          const businessApps = appsResponse.data.filter(
            (item) => item.app.appGroupId === 1
          );
          const paymentApps = appsResponse.data.filter(
            (item) => item.app.appGroupId === 2
          );
          const socialApps = appsResponse.data.filter(
            (item) => item.app.appGroupId === 3
          );
          const customApps = appsResponse.data.filter(
            (item) => item.app.appGroupId === 4
          );
          setBusinessApps(businessApps);
          setPaymentApps(paymentApps);
          setSocialApps(socialApps);
          setCustomApps(customApps);
          getCompanyAppsPayload();
        }
      }
    } catch (error) {
      console.error("Failed to fetch profile colors or company apps", error);
    }
  };

  useEffect(() => {
    assignUserId();
    fetchCompanyId();
    getProfileColorsAndApps();
  }, [companyId]);
  


  const getAllApps = async () => {
    try {
      const response = await api.post("public/AppList", {});
      if (response.data) {
        const allBusinessApps = response.data.filter(
          (item) => item.appGroupId === 1
        );
        const allPaymentApps = response.data.filter(
          (item) => item.appGroupId === 2
        );
        const allSocialApps = response.data.filter(
          (item) => item.appGroupId === 3
        );
        const allCustomApps = response.data.filter(
          (item) => item.appGroupId === 4
        );

        setAllBusinessApps(allBusinessApps);
        setAllPaymentApps(allPaymentApps);
        setAllSocialApps(allSocialApps);
        setAllCustomApps(allCustomApps);
      }
    } catch (error) {
      console.error("Failed to fetch apps", error);
    }
  };

  useEffect(() => {
    getAllApps();
  }, []);

  const handleToggleBusinessApp = (selectedApp) => {
    const isBusinessAppInUserApps = businessApps.some(
      (app) => app.app.id === selectedApp.id
    );

    if (isBusinessAppInUserApps) {
      setBusinessApps((prevBusinessApps) =>
        prevBusinessApps.filter((app) => app.app.id !== selectedApp.id)
      );
    } else {
      const formattedApp = {
        app: {
          appGroupId: selectedApp.appGroupId,
          iconUrl: selectedApp.iconUrl,
          linkPrefix: selectedApp.linkPrefix,
          id: selectedApp.id,
          name: selectedApp.name,
        },
        linkSufix: selectedApp.linkSufix,
      };
      setBusinessApps((prevBusinessApps) => [
        ...prevBusinessApps,
        formattedApp,
      ]);
    }
    const isAppInCommonApps = commonBusinessAppIds.some(
      (app) => app.id === selectedApp.id
    );
    if (!isAppInCommonApps) {
      setCommonBusinessAppIds((prevCommonApps) => [
        ...prevCommonApps,
        selectedApp,
      ]);
    }
  };

  const handleTogglePaymentApp = (selectedApp) => {
    const isPaymentAppInUserApps = paymentApps.some(
      (app) => app.app.id === selectedApp.id
    );

    if (isPaymentAppInUserApps) {
      setPaymentApps((prevPaymentApps) =>
        prevPaymentApps.filter((app) => app.app.id !== selectedApp.id)
      );
    } else {
      const formattedApp = {
        app: {
          appGroupId: selectedApp.appGroupId,
          iconUrl: selectedApp.iconUrl,
          linkPrefix: selectedApp.linkPrefix,
          id: selectedApp.id,
          name: selectedApp.name,
        },
        linkSufix: selectedApp.linkSufix,
      };
      setPaymentApps((prevPaymentApps) => [...prevPaymentApps, formattedApp]);
    }
    const isAppInCommonApps = commonPaymentAppIds.some(
      (app) => app.id === selectedApp.id
    );
    if (!isAppInCommonApps) {
      setCommonPaymentAppIds((prevCommonApps) => [
        ...prevCommonApps,
        selectedApp,
      ]);
    }
  };

  const handleToggleSocialApp = (selectedApp) => {
    const isSocialAppInUserApps = socialApps.some(
      (app) => app.app.id === selectedApp.id
    );

    if (isSocialAppInUserApps) {
      setSocialApps((prevSocialApps) =>
        prevSocialApps.filter((app) => app.app.id !== selectedApp.id)
      );
    } else {
      const formattedApp = {
        app: {
          appGroupId: selectedApp.appGroupId,
          iconUrl: selectedApp.iconUrl,
          linkPrefix: selectedApp.linkPrefix,
          id: selectedApp.id,
          name: selectedApp.name,
        },
        linkSufix: selectedApp.linkSufix,
      };
      setSocialApps((prevSocialApps) => [...prevSocialApps, formattedApp]);
    }
    const isAppInCommonApps = commonSocialAppIds.some(
      (app) => app.id === selectedApp.id
    );

    if (!isAppInCommonApps) {
      setCommonSocialAppIds((prevCommonApps) => [
        ...prevCommonApps,
        selectedApp,
      ]);
    }
  };

  const handleToggleCustomApp = (selectedApp) => {
    const isCustomAppInUserApps = customApps.some(
      (app) => app.app.id === selectedApp.id
    );

    if (isCustomAppInUserApps) {
      setCustomApps((prevCustomApps) =>
        prevCustomApps.filter((app) => app.app.id !== selectedApp.id)
      );
    } else {
      const formattedApp = {
        app: {
          appGroupId: selectedApp.appGroupId,
          iconUrl: selectedApp.iconUrl,
          linkPrefix: selectedApp.linkPrefix,
          id: selectedApp.id,
          name: selectedApp.name,
        },
        linkSufix: selectedApp.linkSufix,
      };
      setCustomApps((prevCustomApps) => [...prevCustomApps, formattedApp]);
    }
    const isAppInCommonApps = commonCustomAppIds.some(
      (app) => app.id === selectedApp.id
    );

    if (!isAppInCommonApps) {
      setCommonCustomAppIds((prevCommonApps) => [
        ...prevCommonApps,
        selectedApp,
      ]);
    }
  };

  return (
    <FullPageWrapper>
    <div style={{ color: 'white' }}>
      <div style={{ textAlign: 'center', color: 'white' }}>
        <Row>
          <Col xs={6} md={4}>
            <TextField
              label="Primary"
              type="color"
              value={primaryColor}
              onChange={handleColorChange(setPrimaryColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
          <Col xs={6} md={4}>
            <TextField
              label="Secondary"
              type="color"
              value={secondaryColor}
              onChange={handleColorChange(setSecondaryColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
          <Col xs={6} md={4}>
            <TextField
              label="Highlight"
              type="color"
              value={highlightColor}
              onChange={handleColorChange(setHighlightColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
          <Col xs={6} md={4}>
            <TextField
              label="Bottom"
              type="color"
              value={bottomColor}
              onChange={handleColorChange(setBottomColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
          <Col xs={6} md={4}>
            <TextField
              label="Panel 1"
              type="color"
              value={namePanelColor}
              onChange={handleColorChange(setNamePanelColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
          <Col xs={6} md={4}>
            <TextField
              label="Panel 2"
              type="color"
              value={companyPanelColor}
              onChange={handleColorChange(setCompanyPanelColor)}
              style={{ margin: '10px', width: '100%', height: '50px' }}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
            />
          </Col>
        </Row>

        <FormControlLabel
          control={
            <Switch
              checked={useWhiteText}
              onChange={(e) => setUseWhiteText(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "white",
                  "& + .MuiSwitch-track": { backgroundColor: "white" },
                },
                "& .MuiSwitch-switchBase": { color: "black", "& + .MuiSwitch-track": { backgroundColor: "#777" } },
              }}
            />
          }
          label={<Typography variant="subtitle1" fontWeight={700} sx={{ color: 'white' }}>{useWhiteText ? "Use White Text" : "Use Black Text"}</Typography>}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingY: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, paddingBottom: 1, color: 'white' }}
          >
            Business Apps
          </Typography>
          <Button
            onClick={() => setShowAllBusinessApps(!showAllBusinessApps)}
            sx={{
              border: "1px solid gray",
              color: 'white',
            }}
          >
            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
          </Button>
        </Box>
        {businessApps.length < 1 ? (
          <div style={{ paddingBottom: "15px", color: 'white' }}>
            No Business Apps Currently Active
          </div>
        ) : null}
        {showAllBusinessApps ? (
          <Grid container spacing={1}>
            {allBusinessApps.map((item, index) => (
              <Grid
                item
                key={index}
                xs={3}
                sm={3}
                md={3}
                sx={{ textAlign: "center", marginBottom: "1em" }}
              >
                <>
                  <Box
                    component="img"
                    sx={{
                      height: "4rem",
                      width: "4rem",
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                      cursor: "pointer",
                      border: commonBusinessAppIds.includes(item.id)
                        ? "2px solid blue"
                        : "none",
                    }}
                    alt="Business App"
                    src={item.iconUrl}
                    onClick={() => handleToggleBusinessApp(item)}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "0", color: 'white' }}
                  >
                    {item.name}
                  </Typography>
                </>
              </Grid>
            ))}
          </Grid>
        ) : null}
        <Grid container spacing={1}>
          {businessApps.map((selectedApp, index) => (
            <Grid
              item
              key={index}
              xs={3}
              sm={3}
              md={3}
              sx={{ textAlign: "center", marginBottom: "1em" }}
            >
              <>
                <Box
                  component="img"
                  sx={{
                    height: "4rem",
                    width: "4rem",
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Business App"
                  src={selectedApp.app.iconUrl}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ marginTop: "0", color: 'white' }}
                >
                  {selectedApp.app.name}
                </Typography>
              </>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingY: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4, color: 'white' }}
          >
            Payment Apps
          </Typography>
          <Button
            onClick={() => setShowAllMoneyApps(!showAllMoneyApps)}
            sx={{
              border: "1px solid gray",
              color: 'white',
            }}
          >
            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
          </Button>
        </Box>
        {paymentApps.length < 1 ? (
          <div style={{ paddingBottom: "15px", color: 'white' }}>
            No Payment Apps Currently Active
          </div>
        ) : null}
        {showAllMoneyApps ? (
          <Grid container spacing={1}>
            {allPaymentApps.map((item, index) => (
              <Grid
                item
                key={index}
                xs={3}
                sm={3}
                md={3}
                sx={{ textAlign: "center", marginBottom: "1em" }}
              >
                <>
                  <Box
                    component="img"
                    sx={{
                      height: "4rem",
                      width: "4rem",
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                      cursor: "pointer",
                      border: commonPaymentAppIds.includes(item.id)
                        ? "2px solid blue"
                        : "none",
                    }}
                    alt="Money App"
                    src={item.iconUrl}
                    onClick={() => handleTogglePaymentApp(item)}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "0", color: 'white' }}
                  >
                    {item.name}
                  </Typography>
                </>
              </Grid>
            ))}
          </Grid>
        ) : null}
        <Grid container spacing={1}>
          {paymentApps.map((selectedApp, index) => (
            <Grid
              item
              key={index}
              xs={3}
              sm={3}
              md={3}
              sx={{ textAlign: "center", marginBottom: "1em" }}
            >
              <>
                <Box
                  component="img"
                  sx={{
                    height: "4rem",
                    width: "4rem",
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Payment App"
                  src={selectedApp.app.iconUrl}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ marginTop: "0", color: 'white' }}
                >
                  {selectedApp.app.name}
                </Typography>
              </>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingY: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4, color: 'white' }}
          >
            Social Apps
          </Typography>
          <Button
            onClick={() => setShowAllSocialApps(!showAllSocialApps)}
            sx={{
              border: "1px solid gray",
              color: 'white',
            }}
          >
            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
          </Button>
        </Box>
        {socialApps.length < 1 ? (
          <div style={{ paddingBottom: "15px", color: 'white' }}>
            No Social Apps Currently Active
          </div>
        ) : null}
        {showAllSocialApps ? (
          <Grid container spacing={1}>
            {allSocialApps.map((item, index) => (
              <Grid
                item
                key={index}
                xs={3}
                sm={3}
                md={3}
                sx={{ textAlign: "center", marginBottom: "1em" }}
              >
                <>
                  <Box
                    component="img"
                    sx={{
                      height: "4rem",
                      width: "4rem",
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                      cursor: "pointer",
                      border: commonSocialAppIds.includes(item.id)
                        ? "2px solid blue"
                        : "none",
                    }}
                    alt="Social App"
                    src={item.iconUrl}
                    onClick={() => handleToggleSocialApp(item)}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "0", color: 'white' }}
                  >
                    {item.name}
                  </Typography>
                </>
              </Grid>
            ))}
          </Grid>
        ) : null}
        <Grid container spacing={1}>
          {socialApps.map((selectedApp, index) => (
            <Grid
              item
              key={index}
              xs={3}
              sm={3}
              md={3}
              sx={{ textAlign: "center", marginBottom: "1em" }}
            >
              <>
                <Box
                  component="img"
                  sx={{
                    height: "4rem",
                    width: "4rem",
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Social App"
                  src={selectedApp.app.iconUrl}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ marginTop: "0", color: 'white' }}
                >
                  {selectedApp.app.name}
                </Typography>
              </>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4, color: 'white' }}
            >
              Custom Apps
            </Typography>
            <Button
              onClick={() => setShowAllCustomApps(!showAllCustomApps)}
              sx={{ border: "1px solid gray", color: 'white' }}
            >
              <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
            </Button>
          </Box>
          {customApps.length < 1 ? (
            <div style={{ paddingBottom: "15px", color: 'white' }}>
              No Custom Apps Currently Active
            </div>
          ) : null}
          {showAllCustomApps && (
            <Grid container spacing={1}>
              {allCustomApps.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={3}
                  sm={3}
                  md={3}
                  sx={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <>
                    <Box
                      component="img"
                      sx={{
                        height: "4rem",
                        width: "4rem",
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        cursor: "pointer",
                        border: commonCustomAppIds.includes(item.id)
                          ? "2px solid blue"
                          : "none",
                      }}
                      alt="Custom App"
                      src={item.iconUrl}
                      onClick={() => handleToggleCustomApp(item)}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ marginTop: "0", color: 'white' }}
                    >
                      {item.name}
                    </Typography>
                  </>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid container spacing={1}>
            {customApps.map((selectedApp, index) => (
              <Grid
                item
                key={index}
                xs={3}
                sm={3}
                md={3}
                sx={{ textAlign: "center", marginBottom: "1em" }}
              >
                <>
                  <Box
                    component="img"
                    sx={{
                      height: "4rem",
                      width: "4rem",
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="Custom App"
                    src={selectedApp.app.iconUrl}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "0", color: 'white' }}
                  >
                    {selectedApp.app.name}
                  </Typography>
                </>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          sx={{ color: 'white' }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={message?.flavor === "alert-danger" ? "error" : "success"}
            sx={{ width: '100%', color: 'white' }}
          >
            {message?.text}
          </Alert>
        </Snackbar>
      </div>
      <div
        className="row-end"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          size={"large"}
          variant={"contained"}
          type={"submit"}
          onClick={saveProfileThemeAndApps}
          sx={{ color: 'white' }}
        >
          SAVE Theme and Apps
        </Button>
        {/* <Button
          size={"large"}
          variant={"contained"}
          type={"submit"}
          onClick={() => fetchProductLink(userId)}
          sx={{ color: 'white' }}
        >
          Subscription Test
        </Button> */}
      </div>
    </div>
  </FullPageWrapper>
  );
  
};
const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 10,
    margin: 5,
    width: 100,
  },
};
export default CompanyTheme;
